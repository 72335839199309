import Vue, { VueConstructor } from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

// Utility redirect view
import Redirect from '@/views/Redirect.vue';
// Main dashboard view
import Dashboard from '@/views/Dashboard.vue';

// Login and logout functionality
import Login from '@/views/Login.vue';
import Logout from '@/views/Logout.vue';
import ChangeTempPassword from '@/views/ChangeTempPassword.vue';

// eslint-disable-next-line import/no-named-as-default-member
import store from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/redirect/:path(.*)',
    name: 'Redirect',
    component: Redirect,
    meta: { hidden: true, layout: 'public', allowAnonymous: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: <VueConstructor<Vue>><unknown>Login,
    meta: { layout: 'public', allowAnonymous: true },
  },
  {
    path: '/change-temp-password',
    name: 'ChangeTempPassword',
    component: <VueConstructor<Vue>><unknown>ChangeTempPassword,
    meta: { layout: 'public', allowAnonymous: true },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: <VueConstructor<Vue>><unknown>Logout,
    meta: { layout: 'public', allowAnonymous: true },
  },
  {
    path: '/',
    redirect: { name: 'Dashboard' },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      breadcrumbGroup: null,
      breadcrumbItem: 'Pagina de pornire',
    },
  },

  //
  // Profile
  //
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/features/profile/Index.vue'),
    meta: {
      breadcrumbGroup: 'Profile',
      breadcrumbItem: 'Contul meu',
    },
  },

  //
  // Goods received notes
  //
  {
    path: '/nir',
    name: 'GoodsReceivedNotes.Index',
    component: () => import(/* webpackChunkName: "goods-received-notes" */ '@/features/goods-received-notes/views/Index.vue'),
    meta: {
      breadcrumbGroup: 'Documente gestiune',
      breadcrumbItem: 'NIR-uri',
    },
  },
  {
    path: '/nir/adauga',
    name: 'GoodsReceivedNotes.Create',
    component: () => import(/* webpackChunkName: "goods-received-notes" */ '@/features/goods-received-notes/views/Details.vue'),
    meta: {
      breadcrumbGroup: 'Documente gestiune',
      breadcrumbGroupRoute: { name: 'GoodsReceivedNotes.Index' },
      breadcrumbItem: 'Adaugă NIR',
    },
  },
  {
    path: '/nir/detalii/:id',
    name: 'GoodsReceivedNotes.Details',
    component: () => import(/* webpackChunkName: "goods-received-notes" */ '@/features/goods-received-notes/views/Details.vue'),
    meta: {
      breadcrumbGroup: 'Documente gestiune',
      breadcrumbGroupRoute: { name: 'GoodsReceivedNotes.Index' },
      breadcrumbItem: 'Detalii NIR',
    },
  },

  //
  // Material requisition notes
  //
  {
    path: '/bcc',
    name: 'MaterialsRequisitionNotes.Index',
    component: () => import(/* webpackChunkName: "materials-requisition-notes" */ '@/features/materials-requisition-notes/views/Index.vue'),
    meta: {
      breadcrumbGroup: 'Documente gestiune',
      breadcrumbItem: 'Bonuri de consum',
    },
  },
  {
    path: '/bcc/adauga',
    name: 'MaterialsRequisitionNotes.Create',
    component: () => import(/* webpackChunkName: "materials-requisition-notes" */ '@/features/materials-requisition-notes/views/Details.vue'),
    meta: {
      breadcrumbGroup: 'Documente gestiune',
      breadcrumbGroupRoute: { name: 'MaterialsRequisitionNotes.Index' },
      breadcrumbItem: 'Adaugă bon de consum',
    },
  },
  {
    path: '/bcc/detalii/:id',
    name: 'MaterialsRequisitionNotes.Details',
    component: () => import(/* webpackChunkName: "materials-requisition-notes" */ '@/features/materials-requisition-notes/views/Details.vue'),
    meta: {
      breadcrumbGroup: 'Documente gestiune',
      breadcrumbGroupRoute: { name: 'MaterialsRequisitionNotes.Index' },
      breadcrumbItem: 'Detalii bon de consum',
    },
  },

  //
  // Inventory asset requisition note
  //
  {
    path: '/bco',
    name: 'InventoryAssetRequisitionNotes.Index',
    component: () => import(/* webpackChunkName: "inventory-asset-requisition-notes" */ '@/features/inventory-asset-requisition-notes/views/Index.vue'),
    meta: {
      breadcrumbGroup: 'Documente gestiune',
      breadcrumbItem: 'Bonuri de consum obiecte inventar',
    },
  },
  {
    path: '/bco/adauga',
    name: 'InventoryAssetRequisitionNotes.Create',
    component: () => import(/* webpackChunkName: "inventory-asset-requisition-notes" */ '@/features/inventory-asset-requisition-notes/views/Details.vue'),
    meta: {
      breadcrumbGroup: 'Documente gestiune',
      breadcrumbGroupRoute: { name: 'InventoryAssetRequisitionNotes.Index' },
      breadcrumbItem: 'Adaugă bon de consum obiect inventar',
    },
  },
  {
    path: '/bco/detalii/:id',
    name: 'InventoryAssetRequisitionNotes.Details',
    component: () => import(/* webpackChunkName: "inventory-asset-requisition-notes" */ '@/features/inventory-asset-requisition-notes/views/Details.vue'),
    meta: {
      breadcrumbGroup: 'Documente gestiune',
      breadcrumbGroupRoute: { name: 'InventoryAssetRequisitionNotes.Index' },
      breadcrumbItem: 'Detalii bon de consum obiect inventar',
    },
  },

  //
  // Inventory asset transfer note
  //
  {
    path: '/bto',
    name: 'InventoryAssetTransferNotes.Index',
    component: () => import(/* webpackChunkName: "inventory-asset-transfer-notes" */ '@/features/inventory-asset-transfer-notes/views/Index.vue'),
    meta: {
      breadcrumbGroup: 'Documente gestiune',
      breadcrumbItem: 'Bonuri de transfer obiecte inventar',
    },
  },
  {
    path: '/bto/adauga',
    name: 'InventoryAssetTransferNotes.Create',
    component: () => import(/* webpackChunkName: "inventory-asset-transfer-notes" */ '@/features/inventory-asset-transfer-notes/views/Details.vue'),
    meta: {
      breadcrumbGroup: 'Documente gestiune',
      breadcrumbGroupRoute: { name: 'InventoryAssetTransferNotes.Index' },
      breadcrumbItem: 'Adaugă bon de transfer obiect inventar',
    },
  },
  {
    path: '/bto/detalii/:id',
    name: 'InventoryAssetTransferNotes.Details',
    component: () => import(/* webpackChunkName: "inventory-asset-transfer-notes" */ '@/features/inventory-asset-transfer-notes/views/Details.vue'),
    meta: {
      breadcrumbGroup: 'Documente gestiune',
      breadcrumbGroupRoute: { name: 'InventoryAssetTransferNotes.Index' },
      breadcrumbItem: 'Detalii bon de transfer obiect inventar',
    },
  },

  //
  // Inventory reports
  //
  {
    path: '/reports/inventory-documents-slip',
    name: 'InventoryReports.InventoryDocumentsSlip',
    component: () => import(/* webpackChunkName: "inventory-reports" */ '@/features/inventory-reports/views/InventoryDocumentsSlip.vue'),
    meta: {
      breadcrumbGroup: 'Rapoarte gestiune',
      breadcrumbItem: 'Borderou de documente',
    },
  },
  {
    path: '/reports/inventory-balance-sheet',
    name: 'InventoryReports.InventoryBalanceSheet',
    component: () => import(/* webpackChunkName: "inventory-reports" */ '@/features/inventory-reports/views/InventoryBalanceSheet.vue'),
    meta: {
      breadcrumbGroup: 'Rapoarte gestiune',
      breadcrumbItem: 'Balanța stocurilor',
    },
  },
  {
    path: '/reports/inventory-journal',
    name: 'InventoryReports.InventoryJournal',
    component: () => import(/* webpackChunkName: "inventory-reports" */ '@/features/inventory-reports/views/InventoryJournal.vue'),
    meta: {
      breadcrumbGroup: 'Rapoarte gestiune',
      breadcrumbItem: 'Mișcări de gestiune',
    },
  },
  {
    path: '/reports/inventory-asset-balance-sheet',
    name: 'InventoryReports.InventoryAssetBalanceSheet',
    component: () => import(/* webpackChunkName: "inventory-reports" */ '@/features/inventory-reports/views/InventoryAssetBalanceSheet.vue'),
    meta: {
      breadcrumbGroup: 'Rapoarte gestiune',
      breadcrumbItem: 'Stocuri obiecte inventar',
    },
  },
  {
    path: '/reports/inventory-asset-journal',
    name: 'InventoryReports.InventoryAssetJournal',
    component: () => import(/* webpackChunkName: "inventory-reports" */ '@/features/inventory-reports/views/InventoryAssetJournal.vue'),
    meta: {
      breadcrumbGroup: 'Rapoarte gestiune',
      breadcrumbItem: 'Mișcări obiecte inventar',
    },
  },

  //
  // Catalog management - Product Catalog
  //
  {
    path: '/catalog-management/product-catalog',
    name: 'CatalogManangement.ProductCatalog.Index',
    component: () => import(/* webpackChunkName: "catalog-management" */ '@/features/catalog-management/product-catalog/Index.vue'),
    meta: {
      breadcrumbGroup: 'Cataloage',
      breadcrumbItem: 'Catalog produse',
    },
  },

  //
  // Application settings - user management
  //
  {
    path: '/application-settings/users',
    name: 'ApplicationSettings.UserManagement.Index',
    component: () => import(/* webpackChunkName: "settings-user-management" */ '@/features/application-settings/user-management/Index.vue'),
    meta: {
      breadcrumbGroup: 'Setări aplicație',
      breadcrumbItem: 'Setări utilizatori',
    },
  },
  {
    path: '/application-settings/users/:userGuid',
    name: 'ApplicationSettings.UserManagement.Details',
    component: () => import(/* webpackChunkName: "settings-user-management" */ '@/features/application-settings/user-management/Details.vue'),
    meta: {
      breadcrumbGroup: 'Setări aplicație',
      breadcrumbItem: 'Setări utilizator',
    },
  },

  //
  // Application settings - inventory management
  //
  {
    path: '/application-settings/inventory',
    name: 'ApplicationSettings.InventoryManagement.Index',
    component: () => import(/* webpackChunkName: "settings-inventory-management" */ '@/features/application-settings/inventory-management/Index.vue'),
    meta: {
      breadcrumbGroup: 'Setări aplicație',
      breadcrumbItem: 'Setări gestiune',
    },
  },
  {
    path: '/application-settings/inventory/:inventoryId',
    name: 'ApplicationSettings.InventoryManagement.Details',
    component: () => import(/* webpackChunkName: "settings-inventory-management" */ '@/features/application-settings/inventory-management/Details.vue'),
    meta: {
      breadcrumbGroup: 'Setări aplicație',
      breadcrumbItem: 'Detalii gestiune',
    },
  },

  //
  // Application settings - document series management
  //
  {
    path: '/application-settings/document-series',
    name: 'ApplicationSettings.DocumentSeriesManagement.Index',
    component: () => import(/* webpackChunkName: "settings-document-series-management" */ '@/features/application-settings/document-series-management/Index.vue'),
    meta: {
      breadcrumbGroup: 'Setări aplicație',
      breadcrumbItem: 'Setări serii documente',
    },
  },

  {
    path: '/application-settings/product-classification',
    name: 'ApplicationSettings.ProductClassification.Index',
    component: () => import(/* webpackChunkName: "settings-document-series-management" */ '@/features/application-settings/product-classification/Index.vue'),
    meta: {
      breadcrumbGroup: 'Setări aplicație',
      breadcrumbItem: 'Clasificări produse',
    },
  },

  //
  // Internal developer tools
  //
  {
    path: '/internal-developer-tools',
    name: 'InternalDeveloperTools.Index',
    component: () => import(/* webpackChunkName: "internal-developer-tools" */ '@/features/internal-developer-tools/views/Index.vue'),
    meta: {
      breadcrumbGroup: null,
      breadcrumbItem: 'Utilitare dezvoltatare 👷',
    },
  },
];

const createRouter = () => new VueRouter({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes,
});

const router = createRouter();

router.afterEach((to) => {
  document.title = '';
  if (to.meta?.breadcrumbItem) {
    document.title += `${to.meta.breadcrumbItem} - EasInventory`;
  }
});

router.beforeEach(async (to, from, next) => {
  const [nearest] = to.matched.slice().reverse().slice(0, 1);

  if (nearest && nearest.meta) {
    store.dispatch('setBreadcrumbs', { route: nearest });
  } else {
    store.dispatch('setBreadcrumbs', { route: {} });
  }

  if (to.matched.some((record) => record.meta.allowAnonymous === true)) {
    next();
  } else {
    if (store.state.isLoggedIn) {
      // Check if we have a role requirement array and if true check the current user's roles against it
      if (nearest
        && nearest.meta.requiresRole
        && Array.isArray(nearest.meta.requiresRole)) {
        const requiredRoles = nearest.meta.requiresRole;
        if (!store.state.userRoles.some((r) => requiredRoles.includes(r))) {
          next('/access-denied');
          return;
        }
      }

      next();
      return;
    }
    next({ name: 'Login' });
  }
});

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
