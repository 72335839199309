/* eslint-disable no-underscore-dangle */
import { Subject, Observable } from 'rxjs';
import { BvToastOptions } from 'bootstrap-vue';
import { VNode } from 'vue';
import { v4 as uuidv4 } from 'uuid';

export class Toasts {
  private _toastSubject = new Subject<[string|Array<VNode>, BvToastOptions]>()

  private _defaultOptions = {
    toaster: 'b-toaster-top-right',
    solid: false,
  }

  get observable(): Observable<[string|Array<VNode>, BvToastOptions]> {
    return this._toastSubject.asObservable();
  }

  toast(message: string|Array<VNode>, options?: BvToastOptions): string {
    const id = uuidv4();
    this._toastSubject.next([message, {
      id,
      ...this._defaultOptions,
      ...options,
    }]);

    return id;
  }

  success(message: string|Array<VNode>, options?: BvToastOptions): string {
    const id = options?.id || uuidv4();
    this.toast(message, {
      title: 'Succes',
      variant: 'success',
      ...options,
    });

    return id;
  }

  error(message: string|Array<VNode>, options?: BvToastOptions): string {
    const id = options?.id || uuidv4();
    this.toast(message, {
      title: 'Error',
      variant: 'danger',
      ...options,
    });

    return id;
  }
}

export const toasts = new Toasts();
