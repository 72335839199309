<template>
  <b-container fluid>
    <b-row class="justify-content-center pt-4">
      <b-card
        no-body
        class="overflow-hidden"
        style="max-width: 720px;"
      >
        <b-row no-gutters>
          <b-col
            md="7"
            sm="12"
            class="d-none d-md-block"
          >
            <b-card-img
              src="/img/warehouse.jpg"
              alt="Image"
              class="rounded-0"
            />
          </b-col>
          <b-col
            md="5"
            sm="12"
          >
            <b-card-body title="Setează parola">
              <b-card-text>
                <b-form @submit.stop.prevent="onSubmit">
                  <b-form-group
                    label="Parolă nouă:"
                    label-for="password"
                  >
                    <b-form-input
                      id="new-password"
                      type="password"
                      v-model="newPassword"
                      autocomplete="new-password"
                      trim
                      required
                      :readonly="xhrRequestRunning"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Confirmă parola nouă:"
                    label-for="confirm-password"
                  >
                    <b-form-input
                      id="confirm-password"
                      type="password"
                      v-model="confirmPassword"
                      autocomplete="new-password"
                      trim
                      required
                      :readonly="xhrRequestRunning"
                    />
                  </b-form-group>

                  <b-button
                    type="submit"
                    variant="primary"
                    :disabled="xhrRequestRunning"
                    class="p-2"
                  >
                    <fa-icon
                      :icon="['fas', 'key']"
                      class="mr-2"
                    />Submit
                  </b-button>
                </b-form>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-row>

    <vue-recaptcha
      v-if="recaptchaSiteKey"
      size="invisible"
      ref="invisibleRecaptcha"
      :sitekey="recaptchaSiteKey"
      :load-recaptcha-script="true"
      recaptcha-host="www.recaptcha.net"
      @verify="onVerify"
      @expired="onExpired"
      @error="onError"
    />
  </b-container>
</template>

<script lang="js">
import { mapState, mapActions } from 'vuex';
import { VueRecaptcha } from 'vue-recaptcha';

import { toasts } from '@/utils/toasts';

export default {
  name: 'ChangeTempPassword',
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      newPassword: null,
      confirmPassword: null,
      recaptcha: null,
      recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    };
  },
  computed: {
    ...mapState(['xhrRequestRunning']),
  },
  methods: {
    ...mapActions([
      'performLogin',
      'performChangeTemporaryPasword',
    ]),
    onVerify(response) {
      this.recaptcha = response;
      this.submitForm();
    },
    onExpired() {
      this.recaptcha = null;
    },
    onError() {
      if (this.recaptchaSiteKey) {
        this.$refs.invisibleRecaptcha.reset();
      }
      toasts.error('A intervenit o eroare, încearcă din nou să te autentifici');
    },
    async onSubmit() {
      if (this.recaptchaSiteKey && !this.recaptcha) {
        this.$refs.invisibleRecaptcha.execute();
      } else {
        await this.submitForm();
      }
    },
    async submitForm() {
      try {
        if (!this.newPassword || !this.confirmPassword) {
          toasts.error('Completati toate campurile');
          return;
        }
        if (this.newPassword !== this.confirmPassword) {
          toasts.error('Parolele introduse nu se potrivesc');
          return;
        }
        const response = await this.performChangeTemporaryPasword({
          newPassword: this.newPassword,
          repeatNewPassword: this.confirmPassword,
        });

        if (!response.errorMessage) {
          toasts.success('Autentificare reușită');
          this.$router.push('/');
        } else {
          toasts.error(response.errorMessage);
          if (this.recaptchaSiteKey) {
            this.$refs.invisibleRecaptcha.reset();
          }
        }
      } catch (err) {
        toasts.error('A intervenit o eroare, încearcă din nou să te autentifici');
        if (this.recaptchaSiteKey) {
          this.$refs.invisibleRecaptcha.reset();
        }
      }
    },
  },
};
</script>
