

















































































































































































































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Dashboard',
})
export default class extends Vue {
  model = 15;

  onChange(...params) {
    console.log('onChange', ...params);
  }
}
